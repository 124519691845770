<script setup lang="ts">
import { useDisplay } from 'vuetify';
import GooglePlayButton from './GooglePlayButton.vue';
import AppStoreButton from './AppStoreButton.vue';
import BottomSheet from '@/components/BottomSheet.vue';

const display = useDisplay();
const isMobile = computed(() => display.xs.value);
const route = useRoute();
const isHomePage = computed(() => route.path === '/');
const showDownloadOptions = ref(false);

const appConfig = useAppConfig();
const appStoreUrl = appConfig.APP_STORE.IOS_URL;
const googlePlayUrl = appConfig.APP_STORE.ANDROID_URL;

// 检测设备类型并返回对应的下载链接
const getDownloadLink = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    const isAndroid = /android/.test(userAgent);
    
    if (isIOS) {
        return appStoreUrl;
    } else if (isAndroid) {
        return googlePlayUrl;
    }
    return '#';
};

const handleDownload = () => {
    const downloadLink = getDownloadLink();
    window.location.href = downloadLink;
};
</script>

<template>
    <!-- 移动端底部下载条 - 仅在首页显示 -->
    <template v-if="isMobile && isHomePage">
        <div class="fixed bottom-0 left-0 w-full z-[45]">
            <div class="bg-[#171717]/80 backdrop-blur-lg flex items-center justify-between px-4 py-3">
                <div class="flex gap-3 items-center">
                    <img src="/idx/bestfaceswap-logo.svg" alt="BestFaceSwap" class="h-8">
                    <div class="flex flex-col">
                        <span class="text-xs font-semibold text-white">BestFaceSwap App</span>
                        <span class="text-xs text-neutral-400">AI Face Editor App</span>
                    </div>
                </div>
                <div class="flex-shrink-0"> 
                    <button @click="handleDownload"
                        class="inline-flex items-center justify-center px-6 py-2 bg-[#F06906] hover:opacity-90 text-white font-medium rounded-lg text-sm">
                        <v-icon size="16" class="mr-1">mdi-download</v-icon>
                        Download
                    </button>
                </div>
            </div>
        </div>
    </template>

    <!-- PC端右下角悬浮图标 - 所有页面都显示 -->
    <template v-else-if="!isMobile">
        <div class="fixed bottom-8 right-8 z-[100]">
            <v-menu location="top" :offset="10" open-on-hover>
                <template v-slot:activator="{ props }">
                    <button v-bind="props" 
                            class="flex justify-center items-center w-14 h-14 rounded-full border shadow-lg backdrop-blur-lg transition-all duration-300 cursor-pointer bg-white/10 hover:bg-white/20 border-white/20">
                            <img src="/public/icon/mobile.svg" alt="" class="h-6">
                    </button>
                </template>
                <div class="flex flex-col gap-2 justify-center bg-[#3c3d40]/80 backdrop-blur-xl rounded-xl p-4 shadow-xl border border-white/10">
                    <AppStoreButton />
                    <GooglePlayButton />
                </div>
            </v-menu>
        </div>
    </template>
</template>

<style scoped>
.v-menu {
    display: inline-block;
}
</style>

